.contact {
    height: 200px;
    width: 100%;
    background-color: black;
}

.contact > h1 {
    padding-top: 20px;
    color: #caa368;
    font-family: Raleway;
    font-size: 40px;
    font-weight: bolder;
    text-align: center;
}

.contact > p{
    color: white;
    text-align: center;
    font-family: Raleway;
    font-size: 14px;
    margin-top: 20px;
}

.icons {
    margin-bottom: 300px;
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.icon-look{
    color: white;
}

.circle-icon{
    background: transparent;
    margin: 0 10px;
    height: 30px;
    width: 30px;
    text-align: center;
    vertical-align: middle;
    line-height: 30px;
    border-radius: 100%;
    border: 2px solid white;
}

.icons > a:hover .circle-icon{
    border: 2px solid #caa368;
}

.icons > a:hover .icon-look{
    color: #caa368;
}