.rectangle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    position: relative;
    background-color: black;
    height: 100vh;
    max-width: 100%;
    z-index: 0;
}

.rectangle img {
    object-fit: cover;
    background-position: 50% 50%;
    display: block;
    top:0;
    margin: auto;
    opacity: 0.35;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.rectangle > h1 {
    margin-top: 280px;
    margin-bottom: 10px;
    font-family: Raleway;
    font-size: 80px;
    font-weight: 600;
    color: #caa368;
}

.rectangle > p {
    margin-bottom: 0px;
    font-family: Raleway;
    font-size: 20px;
    font-weight: 100;
    color: white;
}

.button{
    margin: 0 auto;
    bottom: 10px;
    position: relative;
    display: block;
    background-color: #caa368;
    border: none;
    color: black;
    cursor: pointer;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    text-align: center;
    line-height: 35px;
}

.icons-home {
    margin-bottom: 10vh;
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.icon-look{
    color: white;
}

.circle-icon{
    background: transparent;
    margin: 0 10px;
    height: 30px;
    width: 30px;
    text-align: center;
    vertical-align: middle;
    line-height: 30px;
    border-radius: 100%;
    border: 2px solid white;
}

.icons-home > a:hover .circle-icon{
    border: 2px solid #caa368;
}

.icons-home > a:hover .icon-look{
    color: #caa368;
}

@font-face{
    font-family: 'Raleway';
    src:url("fonts/Raleway-Regular.ttf") format("trueType");
}

@media screen and (max-width: 960px) {

    .button{
        visibility: hidden;
    }
}