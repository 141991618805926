.portfolio {
    height: 1280px;
    width: 100%;
    background-color: white;
}

.portfolio > h1 {
    padding-top: 20px;
    color: #caa368;
    font-family: Raleway;
    font-size: 40px;
    font-weight: bolder;
    text-align: center;
}

.selector{
    display: flex;
    justify-content: center;
    background: black;
    margin: 20px auto;
    width: 500px;
    border-radius: 20px 20px 20px 20px;
}

.filter{
    margin: 10px 15px;
    background: black;
    font-family: Raleway;
    color: white;
    cursor: pointer;
    font-size: 16px;
    border: none;
    outline: none;
}

.filter:hover {
    color:#caa368;
}

.active{
    color: #caa368;
}

.items{
    display: grid;
    grid-template-columns: repeat(3, 250px);
    grid-gap:25px;
    text-align: center;
    justify-content: center;
}

.project {
    box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.1);
    border-radius: 3%;
    position: relative;
    height:250px;
    width: 250px;
    cursor: pointer;
    pointer-events: auto;
    background-color: black;
}

.project img {
    position: absolute;
    left: 0;
    top: 0;
    box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.1);
    border-radius: 3%;
    object-fit: cover;
    opacity: 0.6;
    width: 100%;
    height: 100%;
}

.cover {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius:3%;
    pointer-events: none;
}

.project:hover > .cover{
    background-color: black;
    opacity: 0.5;
}

.text h1 { 
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 18px;
    position: relative;
    z-index: 1;
    font-family: Raleway;
    color:#caa368;
    pointer-events: none;
    text-shadow: #000 0px 0px 10px;
}

.project:hover > .text p {
    padding: 5px 0;
    margin: 0 10px;
    position: relative;
    z-index: 1;
    font-family: Raleway;
    font-size: 14px;
    color: white;
    pointer-events: none;
}

@media screen and (max-width: 960px) {
    .portfolio {
        height: auto;
        padding-bottom: 40px;
    }

    .items {
        grid-template-columns: repeat(2, 250px);
        grid-gap:25px;
    }

    .selector {
        display: grid;
        width: 70%;
    }
}

@media screen and (max-width: 650px) {
    .portfolio {
        height: auto;
        padding-bottom: 40px;
    }

    .items {
        grid-template-columns: repeat(2, 150px);
        grid-gap:15px;
    }

    .project {
        width: 150px;
        height: 150px;
    }

    .selector {
        display: grid;
        width: 70%;
    }

    .text h1 { 
        font-size: 14px;
    }

    .project > .text p {
        font-size: 0px;
    }
}