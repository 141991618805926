.blog {
    height: 600px;
    width: 100%;
    background-color: #f7f7f7;
}

.blog > h1 {
    padding-top: 20px;
    color: #caa368;
    font-family: Raleway;
    font-size: 40px;
    font-weight: bolder;
    text-align: center;
}

.card-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    margin-bottom: 100px;
    height: 480px;
    width: 25%;
    background-color: white;
    position: relative;
}

.card > img{
    object-fit: cover;
    width: 100%;
}

.card > h1 {
    padding-top:10px;
    margin: 0 10px ;
    font-family: Raleway;
    font-size: 18px;
    text-align: start;
}

.card p {
    padding-top: 15px;
    margin: 0 10px;
    font-family: Raleway;
    font-size: 13px;
}

.card a {
    font-family: Raleway;
    font-size: 15px;
    color: #caa368;
    position: relative;
    align-self: flex-start;
    bottom: 0px;
    margin: 0 10px;
    margin-top:auto;
    margin-bottom: 15px;
    padding: 10px 10px;
    text-decoration: none;
    background: black;
    text-align: center;
}

.card a:hover {
    background: #caa368;
    color: black;
}

@media screen and (max-width: 720px) {
    .blog {
        height: auto;
    }

    .card-container {
        display: grid;
    }

    .card {
        display: flex;
        flex-direction: column;
        margin: auto;
        height: auto;
        width: 80%;
        margin: 20px auto;
        padding-bottom: 15px;
    }

    .card a {
        margin-top: 25px;
        width: 80px;
    }

}