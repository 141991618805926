.about {
    display: flex;
    height: 640px;
    max-width: 100%;
}

.pic {
    height: 100%;
    width: 50%;
    background-color: #000;
}

.meText {
    height: 100%;
    width: 50%;
    background-color: #ffffff;
    text-align: center;
    overflow: auto;
}

.pic img{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.about h1{
    padding-top: 50px;
    color: #caa368;
    font-family: Raleway;
    font-size: 40px;
}

.about p {
    padding-top: 30px;
    margin: 0 100px;
    vertical-align: middle;
    line-height: 25px;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 100;
    word-wrap: break-word;
}

@media screen and (max-width: 960px) {
    .about{
        display: inline;
    }

    .about p {
        margin: 50px;
        padding-top: 10px;
    }

    .pic{
        width: 100%;
        height: 100%;
    }

    .meText{
        width: 100%;
        padding-bottom: 40px;
    }
}