/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.experience {
    height: 860px;
    width: 100%;
    background-color: #f7f7f7;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.experience > h1 {
    padding-top: 25px;
    margin-bottom: 30px;
    color: #caa368;
    font-family: Raleway;
    font-size: 40px;
    font-weight: bolder;
    text-align: center;
}

.experience-card{
    display: block;
    clear: both;
    border: 1px solid #ddd;
    -webkit-box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.1);
            box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    height: 150px;
    width: 900px;
    background-color: white;
    position: relative;
    word-wrap: break-word;
}

.imgSection{
    float: left;
    height: 100%;
    width: 150px;
    background-color: white;
    z-index: 1;
}

.imgSection img{
    -o-object-fit: cover;
       object-fit: cover;
    z-index: 2;
    position: relative;
    height: 100%;
    width: 100%;
}
.textSection{
    float: left;
    font-family: Raleway;
    width: 80%;
}

.experience-card h1 {
    padding-top:10px;
    margin: 0 20px;
    font-family: Raleway;
    font-size: 20px;
}

.experience-card p {
    margin: 10px 20px;
    word-wrap: break-word;
    font-size: 14px;
}

@media screen and (max-width: 960px) {
    .experience {
        height: auto;
    }

    .experience-card{
        height: auto;
        width: 60%;
    }

    .imgSection{
        height: 100%;
        width: 100%;
    }

    .textSection{
        float: left;
        width: 100%;
    }
}