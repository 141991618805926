html{
  scroll-behavior: smooth;
}

#home, #about, #experience, #portfolio, #articles {
    scroll-margin-top: 82px;
}

@font-face{
  font-family: 'Raleway';
  src:url("fonts/Raleway-Regular.ttf") format("trueType");
}

.navbar {
  background: transparent;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.navbar.active{
  background:white;
  border-bottom: 2px solid rgba(0,0,0,0.15);;
}

.nav-menu {
	display: grid;
  grid-template-columns: repeat(6, auto);
	grid-gap: 10px;
	right: 0px;
	width: 80vw;
  list-style: none;
  text-align: center;
	justify-content: end;
}

.menu-icon {
  display: none;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 100%;
}

.navbar-logo > img {
	justify-self: start;
	margin-left: 20px;
	cursor: pointer;
	display: flex;
	align-items: center;
  width:50px;
	height:30px;
}

.nav-item {
    display: inline;
}
.nav-links {
    display: block;
    padding: 30px 30px;
    text-decoration: none;
    font-family: Raleway;
    font-weight: lighter;
    font-size: 16px;
    color: white;
}

.nav-links:hover{
	color: #caa368;
}

.nav-links.active{
	color:#000;
}

.nav-links.active:hover{
    background-color: black;
    color:#caa368;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.fa-bars {
  color: #fff;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 80px;
    height: calc(100vh - 80px);
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    padding-top: 10px;
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .navbar{
    background:white;
    border-bottom: 2px solid rgba(0,0,0,0.15);;
  }

  .nav-links{
    text-align: center;
    padding: 30px 0px;
    width: 100%;
    display: table;
  }

  .nav-links.active{
	  color: #fff;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 12px;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #caa368;
    font-size: 2rem;
  }

  .fa-bars {
  	color: #caa368;
	}

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    /*padding: 14px 20px;*/
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}